@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';




@font-face {
  font-family: "Aeonik Pro Medium";
  src: url("https://db.onlinewebfonts.com/t/81c9cfcec66a1bb46e90e184f4d04641.eot");
  src: url("https://db.onlinewebfonts.com/t/81c9cfcec66a1bb46e90e184f4d04641.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/81c9cfcec66a1bb46e90e184f4d04641.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/81c9cfcec66a1bb46e90e184f4d04641.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/81c9cfcec66a1bb46e90e184f4d04641.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/81c9cfcec66a1bb46e90e184f4d04641.svg#Aeonik Pro Medium")format("svg");
}

body {
  margin: 0;
  font-family: "Aeonik Pro Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


